// This file defines the metadata schema and parsing functions
// for the select components. Any component with
// options may decide to use these utilities :)
import { z } from "zod";
import type { SafeParseSuccess } from "zod";
import { Result } from "@pairtreefamily/utils";
import {
  DreamcatcherOption,
  DreamcatcherOptionSchema,
} from "./dreamcatcherOption";
import { MetadataTestResult, testMetadata } from "../../metadata";
import { Metadata } from "../../..";

export const DreamcatcherInputMetadataWithOptionsSchema = z.object({
  options: z
    .string()
    // Refined to array of options
    .refine((value) => {
      try {
        // Assumed to be empty array.. transformed in the next .transform
        if (value == "") {
          return true;
        }
        const parsed = JSON.parse(value);
        if (!Array.isArray(parsed)) return false;
        for (const option of parsed) {
          DreamcatcherOptionSchema.parse(option);
        }
        return true;
      } catch (e) {
        return false;
      }
    }, "Must be a valid array of options")
    .transform((value) => {
      if (value == "") {
        return "[]";
      }
      return value;
    }),
});

export type DreamcatcherInputMetadataWithOptions = z.infer<
  typeof DreamcatcherInputMetadataWithOptionsSchema
>;

export function testMetadataHasOptions<M extends Metadata>(
  metadata: M,
): MetadataTestResult<M, DreamcatcherInputMetadataWithOptions> {
  return testMetadata(metadata, DreamcatcherInputMetadataWithOptionsSchema);
}

export const getDreamcatcherOptionMetadata = (
  metadata: DreamcatcherInputMetadataWithOptions | undefined,
) => {
  if (!metadata) return [];
  let jsonOptions: any[];
  try {
    jsonOptions = JSON.parse(metadata.options);
  } catch (e) {
    return [];
  }
  return jsonOptions
    .map((option) => DreamcatcherOptionSchema.safeParse(option))
    .filter(
      (result): result is SafeParseSuccess<DreamcatcherOption> =>
        result.success,
    )
    .map((result) => result.data);
};
