import { Switch } from "@headlessui/react";
import { getClassName } from "@pairtreefamily/utils";

const colorBgMap = {
  darkblue: "bg-darkblue",
  teal: "bg-teal",
  green: "bg-green",
};

const colorBorderMap = {
  darkblue: "border-darkblue",
  teal: "border-teal",
  green: "border-green",
};

export type ToggleProps = {
  enabled: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  color?: "teal" | "darkblue" | "green";
};

export function Toggle(props: ToggleProps) {
  const { enabled, onChange, label, color } = props;

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={onChange}
        className={getClassName(
          enabled
            ? `${color ? colorBorderMap[color] : "border-teal"} ${
                color ? colorBgMap[color] : "bg-teal"
              }`
            : `border-lightgray bg-lightgray`,
          "focus:ring-indigo-600 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2",
        )}
      >
        <span
          aria-hidden="true"
          className={getClassName(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
          )}
        />
      </Switch>
      {label?.length && (
        <Switch.Label as="span" className="text-sm ml-3">
          <span className="text-gray-900 font-medium">{label}</span>
        </Switch.Label>
      )}
    </Switch.Group>
  );
}
export default Toggle;
