import { z } from "zod";
import { DreamcatcherInputPlugin } from ".";
import { useDreamcatcherInputPathAt } from "..";
import { BaseDreamcatcherInput } from "../..";
import { TextInput } from "../../..";
import { DreamcatcherInputBaseMetadataSchema } from "../metadata";
import { DreamcatcherInputMetadataWithLabelSchema } from "../metadata";

// Declare the inputType; useful for having one spot to change the type.
type inputType = "text";

// Declare the input (metadata) shape.
const DreamcatcherTextInputMetadataSchema = z
  .object({
    placeholder: z.string().optional(),
  })
  .merge(DreamcatcherInputMetadataWithLabelSchema)
  .merge(DreamcatcherInputBaseMetadataSchema);

// Declare the input (metadata) type - usually derived from the schema.
export type DreamcatcherTextInputMetadata = z.infer<
  typeof DreamcatcherTextInputMetadataSchema
>;

export type DreamcatcherTextInput = BaseDreamcatcherInput<
  DreamcatcherTextInputMetadata,
  inputType
>;

// Declare the Input component.
export const DreamcatcherTextInputComponent = (
  props: DreamcatcherTextInput,
) => {
  // Get the dreamcatcher value/setValue state. Uses the input id to get the
  // correct value/setValue state.
  const { value, setValue } = useDreamcatcherInputPathAt(props.id);
  // Use the schema to parse the metadata.
  const metadata = DreamcatcherTextInputMetadataSchema.parse(props.metadata);

  return (
    <div className="flex">
      <div className="m-auto w-full max-w-[360px]">
        <TextInput
          type="text"
          label={metadata.label ?? ""}
          value={value ? value[0] : ""}
          placeholder={metadata.placeholder}
          onChange={(value) => {
            setValue([value]);
          }}
        />
      </div>
    </div>
  );
};

// Declare the plugin.
export const DreamcatcherTextInputPlugin: DreamcatcherInputPlugin<
  DreamcatcherTextInputMetadata,
  inputType
> = {
  component: DreamcatcherTextInputComponent,
  type: "text",
  name: "Text",
  metadataSchema: DreamcatcherTextInputMetadataSchema,
};

// Now, export from a barrel file,
// and import in `inputPlugins`.
