import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";
import { DetailedHTMLProps } from "react";

type FormProps<FormValues extends Record<string, any>> = {
  formMethods: UseFormReturn<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
} & Omit<
  DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
  "onSubmit"
>;

export const Form = <FormValues extends Record<string, any>>({
  children,
  onSubmit,
  formMethods,
  ...rest
}: FormProps<FormValues>) => {
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} {...rest}>
        {children}
      </form>
    </FormProvider>
  );
};
