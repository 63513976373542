import { z } from "zod";
import { DreamcatcherConditionalConfig } from "./conditionals";

export type BaseDreamcatcherInput<
  M extends Metadata = Metadata,
  T extends string = string,
> = {
  id: string;
  type: T;
  object: "input";
  conditional?: DreamcatcherConditionalConfig;
  metadata?: M;
};

export const MetadataSchema = z.record(z.string().optional());
export type Metadata = z.infer<typeof MetadataSchema>;
