import { z } from "zod";
import { BaseDreamcatcherInput } from "../../..";
import { Loading } from "../../../..";
import { DreamcatcherInputPlugin } from "../../inputs";
import { DreamcatcherInputBaseMetadataSchema } from "../../metadata";
import { DreamcatcherInputMetadataWithLabelSchema } from "../../metadata";
import { MetadataLabel } from "../../metadata";
import { DreamcatcherSingleSelectComponent } from "../select/SingleSelect";
import {
  DreamcatcherRemoteConfiguration,
  DreamcatcherRemoteConfigurationSchema,
  useDynamicAPIOptions,
} from "./shared";

type inputType = "dynamic-single-select";

export const DreamcatcherDynamicSingleSelectMetadataSchema =
  DreamcatcherRemoteConfigurationSchema.merge(
    DreamcatcherInputMetadataWithLabelSchema,
  ).merge(DreamcatcherInputBaseMetadataSchema);

export type DreamcatcherDynamicSingleSelectMetadata = z.infer<
  typeof DreamcatcherDynamicSingleSelectMetadataSchema
>;

export type DynamicSingleSelectInput = BaseDreamcatcherInput<
  DreamcatcherDynamicSingleSelectMetadata,
  inputType
>;

const DynamicSingleSelect = (props: DynamicSingleSelectInput) => {
  if (!props.metadata) {
    throw new Error("Metadata is required for DynamicSingleSelect");
  }

  const remote = DreamcatcherRemoteConfigurationSchema.parse(props.metadata);

  const { options, status, error } = useDynamicAPIOptions(props.id, remote);
  const childProps = {
    ...props,
    type: "single-select" as "single-select",
    metadata: {
      ...props.metadata,
      options: JSON.stringify(options),
    },
  };
  return (
    <div>
      <>
        {props.metadata && <MetadataLabel metadata={props.metadata} />}
        {status == "pending" && <Loading />}
        {error && (
          <div className="text-red-600">
            <>Error: {error.toString()}</>
          </div>
        )}
        <DreamcatcherSingleSelectComponent {...childProps} />
      </>
    </div>
  );
};

const DynamicSingleSelectPlugin: DreamcatcherInputPlugin<
  DreamcatcherDynamicSingleSelectMetadata,
  inputType
> = {
  component: DynamicSingleSelect,
  type: "dynamic-single-select",
  name: "Dynamic Single-select",
  metadataSchema: DreamcatcherDynamicSingleSelectMetadataSchema,
};

export default DynamicSingleSelectPlugin;
