export type TextInputProps = {
  value: string;
  name?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  label: JSX.Element | string;
  "data-test"?: string;
  type?: "text" | "password" | "email";
  disabled?: boolean;
};

export function TextInput(props: TextInputProps) {
  const {
    value,
    name,
    placeholder,
    onChange,
    label,
    type = "text",
    disabled,
  } = props;
  return (
    <label className="my-4 flex flex-col items-start">
      <span className="text-body-4-semi mb-[5px]">{label}</span>
      <input
        type={type}
        value={value}
        data-test={props["data-test"]}
        name={name}
        placeholder={placeholder}
        className="text-body-2 h-[50px] w-full rounded-lg border border-platinum px-4 py-1.5 placeholder:italic placeholder:text-quicksilver focus:border-teal disabled:opacity-50"
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      />
    </label>
  );
}
