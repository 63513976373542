import {
  DreamcatcherCondition,
  DreamcatcherStepProps,
  resolveAllConditions,
  resolveDreamcatcherCondition,
} from "../schemas";
import {
  DreamcatcherPathProvider,
  useDreamcatcherValueAccessor,
} from "./DreamcatcherPathContext";
import { getInputComponent } from "./inputs/plugins";
import MetadataTooltip from "./metadata/MetadataTooltip";
import { testMetadataHasTooltip } from "./metadata/tooltip";

export const DreamcatcherStep = ({
  title,
  description,
  id,
  inputs,
  metadata,
}: DreamcatcherStepProps) => {
  const getValueAtPath = useDreamcatcherValueAccessor();
  const tooltipMetadataRes = testMetadataHasTooltip(metadata ?? {});
  return (
    <DreamcatcherPathProvider path={id}>
      <div className="text-head-3 mb-2.5 text-center">
        <em>{title}</em>
      </div>
      {description && (
        <div className="text-body-3 text-center">{description}</div>
      )}
      {tooltipMetadataRes.success && (
        <p className="text-center text-teal">
          <MetadataTooltip metadata={tooltipMetadataRes.metadata} />
        </p>
      )}
      <div className="mb-7 mt-16" />
      {inputs.map((input, idx) => {
        if (input.conditional) {
          const conditionResolver = (condition: DreamcatcherCondition) =>
            resolveDreamcatcherCondition(condition, getValueAtPath);
          const conditionsMet = resolveAllConditions(
            input.conditional,
            conditionResolver,
          );

          if (!conditionsMet) {
            // TODO for flow-building we may want to apply some class (e.g.
            // animate-pulse) instead of removing from the tree
            return null;
          }
        }

        const InputComponent = getInputComponent(input.type);

        return (
          <div key={input.id}>
            {idx > 0 && <hr className="my-6 border-2 border-backgroundgray" />}
            {InputComponent && <InputComponent {...input} />}
          </div>
        );
      })}
    </DreamcatcherPathProvider>
  );
};
