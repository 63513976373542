export type RadioInputProps = {
  value: string;
  groupName: string;
  onChange: (checkedValue: string) => void;
  checked: boolean | null;
};

export function RadioInput({
  value,
  groupName,
  onChange,
  checked,
}: RadioInputProps) {
  return (
    <label className="text-body-3-medium content-center">
      <input
        checked={checked ?? false}
        onChange={() => onChange(value)}
        className="mr-1 text-teal"
        type="radio"
        name={groupName}
        value={value}
      />
      {/*TODO refactor this to use a label prop instead of value as this can be different */}
      {value}
    </label>
  );
}
