import { Metadata } from "../..";
import { AnyZodObject, z } from "zod";

export type MetadataTestResult<M extends Metadata, S> =
  | {
      success: true;
      metadata: M & S;
    }
  | {
      success: false;
      metadata: M;
    };

export function testMetadata<M extends Metadata, S extends AnyZodObject>(
  metadata: M,
  schema: S,
): MetadataTestResult<M, z.infer<S>> {
  const parseRes = schema.safeParse(metadata);
  if (parseRes.success) {
    return {
      success: true,
      metadata: { ...metadata, ...parseRes.data },
    };
  } else {
    return {
      success: false,
      metadata: metadata,
    };
  }
}
