import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Autocomplete, AutocompleteProps } from "../../Autocomplete";

export interface AutocompleteFieldProps
  extends Omit<AutocompleteProps, "onChange" | "value"> {
  name: string;
}

export const AutocompleteField = ({
  name,
  ...rest
}: AutocompleteFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field }) => {
      return (
        <Autocomplete
          onChange={field.onChange}
          value={field.value}
          name={name}
          {...rest}
        />
      );
    },
    [name, rest],
  );

  return <Controller name={name} render={render} />;
};
