import { useCallback } from "react";

export type TextAreaProps = {
  value: string;
  onChange: (value: string) => void;
  label: JSX.Element | string;
  name?: string;
};

export function TextArea({ name, onChange, label, value }: TextAreaProps) {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <label className="my-4 flex flex-col items-start">
      <span className="text-body-4-semi">{label}</span>

      <textarea
        name={name}
        className="w-full resize-none p-2"
        value={value}
        onChange={handleChange}
      />
    </label>
  );
}
