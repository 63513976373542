import { Err, getClassName, Ok, Result } from "@pairtreefamily/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CheckMarkIcon, TextInput } from "..";

export type DateInputProps = {
  value: Date | null;
  onChange: (date: Date | null | ValidationError) => void;
  label: string;
};

export type ValidationError = {
  errorType: DateParseErrorType;
  message?: string | null;
};

export function DateInput({ label, onChange, value }: DateInputProps) {
  const [year, _setYear] = useState<string>("");
  const [month, _setMonth] = useState<string>("");
  const [day, _setDay] = useState<string>("");
  const internalDate = useRef<Date | null>(null);
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);

  const validationErrors = {
    "missing-input": null,
    "illegitimate-date":
      "Invalid date: please double check the combination of month/day/year",
    "invalid-input": "Invalid values for month/date/year",
    "invalid-year": "Invalid date: invalid year",
    "invalid-date": "Invalid date",
    "missing-a-field": "",
  };

  // handles changes to any of the "DateValues", the three consituent inputs
  // which combine to form a Date
  const onDateValueChange = useCallback(
    (year: string, month: string, day: string) => {
      const maybeDate = constructNewDate({ year, month, day });
      if (maybeDate.ok) {
        const d = maybeDate.content.date;
        internalDate.current = d;
        setValidationError(null);

        // update consumers
        onChange(d);
      } else {
        internalDate.current = null;
        const errorType = maybeDate.error.type;
        setValidationError({ errorType, message: validationErrors[errorType] });

        // update consumers - checking for undefined so we can check if there isn't something on the field (null), or if the date is invalid (undefined)
        onChange({ errorType, message: validationErrors[errorType] });
      }
    },
    [onChange, internalDate],
  );

  const updateMonth = (m: string) => {
    _setMonth(m);
    onDateValueChange(year, m, day);
  };

  const updateDay = (d: string) => {
    _setDay(d);
    onDateValueChange(year, month, d);
  };

  const updateYear = (y: string) => {
    _setYear(y);
    onDateValueChange(y, month, day);
  };

  // handles date changes from consumers (via `value` prop)
  const onExternalDateChange = useCallback(
    (date: Date | null) => {
      if (date === null || isInvalidDate(date)) {
        // if our consumer gives us an invalid date, and we're in an invalid
        // state, do nothing
        if (internalDate.current === null) {
          return;
        }
        // otherwise, update our inputs (and internal date) to reflect this new date
        internalDate.current = null;
        _setYear("");
        _setMonth("");
        _setDay("");
        // TODO clearing the error to match the value inputs, but this may
        // warrant a separate error message
        setValidationError(null);
        return;
      }

      // if the external date matches our internal date, skip the update
      // this is mostly to support consumers who convert date to/from strings;
      // the date object will change, but the stringified date is a proxy
      // for the "real" date
      if (date.toISOString() === internalDate.current?.toISOString()) {
        return;
      }

      const vals = getLocalDateValues(date);
      internalDate.current = date;
      setValidationError(null);
      _setYear(vals.year);
      _setMonth(vals.month);
      _setDay(vals.day);
    },
    [internalDate],
  );

  const placeholders = useMemo(() => {
    const todayDate = new Date();
    return getLocalDateValues(todayDate);
  }, []);

  useEffect(() => {
    onExternalDateChange(value);
  }, [value, onExternalDateChange]);

  return (
    <div>
      <div className="-mb-2 mt-6 flex flex-row items-center">
        <p className="text-body-3-semi">{label}</p>
      </div>

      <div className="flex w-[300px] flex-row items-center gap-2">
        <div className="basis-1/4">
          <TextInput
            value={month ?? ""}
            label={"Month"}
            onChange={updateMonth}
            placeholder={placeholders.month}
          />
        </div>
        <div className="basis-1/4">
          <TextInput
            value={day ?? ""}
            label={"Day"}
            onChange={updateDay}
            placeholder={placeholders.day}
          />
        </div>
        <div className="basis-1/2">
          <TextInput
            value={year ?? ""}
            label={"Year"}
            onChange={updateYear}
            placeholder={placeholders.year}
          />
        </div>
        <CheckMarkIcon
          className={getClassName(
            "ml-2 mt-4 text-green",
            (!internalDate.current || isInvalidDate(internalDate.current)) &&
              "invisible",
          )}
        />
      </div>
      {validationError && (
        <p className="text-body-3 text-rust">
          {validationErrors[validationError.errorType]}
        </p>
      )}
    </div>
  );
}

function getLocalDateValues(date: Date): DateValues {
  return {
    year: String(date.getFullYear()),
    month: String(date.getMonth() + 1),
    day: String(date.getDate()),
  };
}

type MaybeDate = Result<{ isoString: string; date: Date }, DateParseError>;

type DateParseError = {
  inputValues: DateValues;
  type: DateParseErrorType;
};

type DateParseErrorType =
  | "missing-input"
  | "illegitimate-date"
  | "invalid-input"
  | "invalid-year"
  | "missing-a-field";

function constructNewDate(inputValues: DateValues): MaybeDate {
  const { year: yearString, month: monthString, day: dayString } = inputValues;

  if (monthString == "" && dayString == "" && yearString == "") {
    return Err({
      type: "missing-input",
      inputValues: inputValues,
    });
  }
  if (monthString == "" || dayString == "" || yearString == "") {
    return Err({
      type: "missing-a-field",
      inputValues: inputValues,
    });
  }
  const year = coalesceNaN(parseInt(yearString), null);
  const month = coalesceNaN(parseInt(monthString), null);
  const day = coalesceNaN(parseInt(dayString), null);

  if (year === null || month === null || day === null) {
    return Err({ type: "invalid-input", inputValues: inputValues });
  }

  // manually ensure that year is exactly 4 digits
  // the Date api allows other years, but we have no need for them
  if (Math.floor(Math.log10(year)) !== 3) {
    return Err({
      type: "invalid-year",
      inputValues: inputValues,
    });
  }

  // month is zero indexed in the date api
  const date = new Date(year, month - 1, day);

  // if the constructed Date object is invalid
  if (isInvalidDate(date)) {
    return Err({ type: "invalid-input", inputValues: inputValues });
  }

  // validate the date
  // why do this? the `(year, month, day)` form of the Date constructor
  // allows overflows and underflows, meaning something like `(2023, 00, 32)`
  // will create a Date equivalent to `(2023, 01, 01)`
  // ("January 32nd ~= February 1st")
  // To validate the legitimacy of the date we've parsed, we check the
  // constituent inputs against the parsed date's local values
  const { year: y, month: m, day: d } = getLocalDateValues(date);
  const yearsMatch = parseInt(y) === year;
  const monthsMatch = parseInt(m) === month;
  const daysMatch = parseInt(d) === day;
  if (!(yearsMatch && daysMatch && monthsMatch)) {
    return Err({
      type: "illegitimate-date",
      inputValues: inputValues,
    });
  }

  const datestring = date.toISOString(); // "yyyy-mm-dd..."
  return Ok({
    isoString: datestring,
    date: date,
  });
}

type DateValues = {
  year: string;
  month: string;
  day: string;
};

function coalesceNaN<T>(maybeNumber: number, fallback: T): number | T {
  if (Number.isNaN(maybeNumber)) {
    return fallback;
  }
  return maybeNumber;
}

function isInvalidDate(date: Date): boolean {
  return Number.isNaN(date.valueOf());
}

export default DateInput;
