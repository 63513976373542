import { BaseDreamcatcherInput, useDreamcatcherInputPathAt } from "../../..";
import { DreamcatcherInputPlugin } from "../";
import { DreamcatcherInputMetadataWithOptionsSchema } from "./shared";
import { useDreamcatcherOptionMetadata } from ".";
import { DreamcatcherInputMetadataWithLabelSchema } from "../../metadata";
import { MetadataLabel } from "../../metadata";
import { z } from "zod";
import { DreamcatcherInputBaseMetadataSchema } from "../../metadata";
import { Select } from "@pairtreefamily/ui";

const DreamcatcherSingleSelectDropdownMetadataSchema = z
  .object({
    placeholder: z.string().optional(),
  })
  .merge(DreamcatcherInputMetadataWithOptionsSchema)
  .merge(DreamcatcherInputMetadataWithLabelSchema)
  .merge(DreamcatcherInputBaseMetadataSchema);

export type DreamcatcherSingleSelectDropdownMetadata = z.infer<
  typeof DreamcatcherSingleSelectDropdownMetadataSchema
>;

export type DreamcatcherSingleSelectDropdownInput = BaseDreamcatcherInput<
  DreamcatcherSingleSelectDropdownMetadata,
  "single-select-dropdown"
>;

export const DreamcatcherSingleSelectDropdownComponent = (
  props: DreamcatcherSingleSelectDropdownInput,
) => {
  const { setValue, value } = useDreamcatcherInputPathAt(props.id);
  const optionsRes = useDreamcatcherOptionMetadata(props.metadata);

  if (!optionsRes.supportsOptions) {
    return null;
  }
  return (
    <div className="flex">
      <div className="m-auto w-full max-w-[360px]">
        {props.metadata && <MetadataLabel metadata={props.metadata} />}
        <Select
          color="teal"
          placeholder={props?.metadata?.placeholder ?? ""}
          value={value ? value[0] : ""}
          options={props.metadata ? JSON.parse(props.metadata?.options) : []}
          onChange={(value) => {
            setValue([value]);
          }}
        />
      </div>
    </div>
  );
};

const DreamcatcherSingleSelectDropdownInputPlugin: DreamcatcherInputPlugin<
  DreamcatcherSingleSelectDropdownMetadata,
  "single-select-dropdown"
> = {
  type: "single-select-dropdown",
  name: "Single Select (Dropdown)",
  component: DreamcatcherSingleSelectDropdownComponent,
  metadataSchema: DreamcatcherSingleSelectDropdownMetadataSchema,
};

export default DreamcatcherSingleSelectDropdownInputPlugin;
