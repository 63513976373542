import { z } from "zod";
import { Metadata } from "../..";
import { MetadataTestResult, testMetadata } from "./testMetadata";

export const DreamcatcherInputMetadataWithLabelSchema = z.object({
  label: z.string().optional(),
});

export type DreamcatcherInputMetadataWithLabel = z.infer<
  typeof DreamcatcherInputMetadataWithLabelSchema
>;

export function testMetadataHasLabel<M extends Metadata>(
  metadata: M,
): MetadataTestResult<M, DreamcatcherInputMetadataWithLabel> {
  return testMetadata(metadata, DreamcatcherInputMetadataWithLabelSchema);
}
