import { Result } from "@pairtreefamily/utils";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useCoreAPI } from "../hooks/useCoreAPI";
import { ConfidentialAccount, RegisterPayload } from "../types/coreApiMirror";

export type AccountContextData = {
  account: ConfidentialAccount | null;
  isLoading: boolean;
  createAccount: (
    registerPayload: RegisterPayload,
  ) => Promise<Result<ConfidentialAccount, string>>;
};

export const AccountContext = createContext<AccountContextData | null>(null);

export type AccountProviderProps = {
  children: ReactNode;
};

export const AccountProvider = (props: AccountProviderProps) => {
  const CoreAPI = useCoreAPI();
  const [account, setAccount] = useState<ConfidentialAccount | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    if (CoreAPI.isAuthenticated()) {
      console.log("get account");
      CoreAPI.getAccount().then((accountResult) => {
        if (accountResult.ok) {
          setAccount(accountResult.content);
        }
      });
    } else {
      setAccount(null);
    }
    setIsLoading(false);
  }, [CoreAPI]);

  const createAccount = async (registerPayload: RegisterPayload) => {
    const result = await CoreAPI.registerToken(registerPayload);
    if (!result.ok) {
      return {
        ok: false,
        error: JSON.stringify(result.error),
      } as Result<ConfidentialAccount, string>;
    }
    setAccount(result.content);
    return result;
  };

  return (
    <AccountContext.Provider value={{ account, isLoading, createAccount }}>
      {props.children}
    </AccountContext.Provider>
  );
};
