import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import DateInput, { DateInputProps } from "../../DateInput";

export interface DateInputFieldProps
  extends Omit<DateInputProps, "onChange" | "value"> {
  name: string;
}

export const DateInputField = ({ name, ...rest }: DateInputFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field }) => {
      return (
        <DateInput onChange={field.onChange} value={field.value} {...rest} />
      );
    },
    [rest],
  );

  return <Controller name={name} render={render} />;
};
