import { ReactNode } from "react";
import { getClassName } from "@pairtreefamily/utils";
import NavigationIndicator from "./navigationIndicator";

type LayoutProps = {
  children: ReactNode;
};
export function Layout({ children }: LayoutProps) {
  return (
    <div className="flex w-full flex-col">
      <NavigationIndicator />
      <div className={maskPortalRootIdentifier} />

      <main
        className={getClassName(
          "min-h-screen grow",
          // ensure content is accessible, but doesn't overflow
          // TODO shouldn't have overflow-anything on main/body, but instead
          // should audit all components that are overflowing and adjust them
          // appropriately. This is a short-term hack to improve mobile
          // experience
          "overflow-x-scroll md:overflow-x-visible",
        )}
      >
        {children}
      </main>
    </div>
  );
}

const maskPortalRootIdentifier = "mask-portal-root";
export const maskPortalRootClass = `.${maskPortalRootIdentifier}`;

export default Layout;
