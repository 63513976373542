import "../styles/globals.css";
import "../styles/fonts.css";
import "../styles/nprogress.css";
import type { AppProps } from "next/app";
import Layout from "../components/layout";
import { CoreAPIProvider } from "../components/CoreAPIProvider";
import { AuthStatusProvider, PosthogProvider } from "@pairtreefamily/ui";
import { app } from "../utils/firebaseConfig";
import { AccountContext, AccountProvider } from "../components/AccountProvider";
import Head from "next/head";
import Analytics from "@/components/analytics";
import dynamic from "next/dynamic";
import { GoogleTagManager } from "@next/third-parties/google";

export interface SharedPageProps {
  preview: boolean;
  token: string;
}

const PreviewProvider = dynamic(() => import("../components/PreviewProvider"));

function MyApp({ Component, pageProps, router }: AppProps<SharedPageProps>) {
  const { preview, token } = pageProps;

  return (
    <AuthStatusProvider app={app}>
      <CoreAPIProvider>
        <AccountProvider>
          <AccountContext.Consumer>
            {(data) => (
              <PosthogProvider
                posthogApiHost="https://app.posthog.com"
                posthogKey="phc_diDZvAW4iIc3f0S7osS64ci8rJyl1DprDKKD7jHfsf2"
                accountUuid={data?.account?.uuid ?? null}
                enabled={
                  process.env.NEXT_PUBLIC_ENABLE_POSTHOG == "true" ||
                  process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
                }
                queryParams={router.query as Record<string, string>}
              >
                <Head>
                  <title>PairTree</title>
                </Head>
                <Layout>
                  {preview ? (
                    <PreviewProvider token={token}>
                      <Component {...pageProps} />
                    </PreviewProvider>
                  ) : (
                    <Component {...pageProps} />
                  )}
                  <Analytics />
                  <GoogleTagManager gtmId="GTM-WXQLBWP" />
                </Layout>
              </PosthogProvider>
            )}
          </AccountContext.Consumer>
        </AccountProvider>
      </CoreAPIProvider>
    </AuthStatusProvider>
  );
}

export default MyApp;
