import { getClassName } from "@pairtreefamily/utils";
import { ComponentColor } from "../types";

const colorMap = {
  darkblue: "text-darkblue",
  backgroundgray: "text-backgroundgray",
  teal: "text-teal",
  green: "text-green",
  "backgroundgray-shade": "text-backgroundgray-shade",
};

const colorBgMap = {
  darkblue: "bg-darkblue",
  backgroundgray: "bg-backgroundgray",
  teal: "bg-teal",
  green: "bg-green",
  "backgroundgray-shade": "bg-backgroundgray-shade",
};

const colorBorderMap = {
  darkblue: "border-darkblue",
  backgroundgray: "border-backgroundgray",
  teal: "border-teal",
  green: "border-green",
  "backgroundgray-shade": "border-backgroundgray-shade",
};

interface OptionsListProps {
  selected?: boolean;
  active?: boolean;
  color?: ComponentColor;
}

export const getOptionClassName = ({
  selected,
  active,
  color,
}: OptionsListProps) => {
  const selectedColor = color ? colorMap[color] : "";
  const activeColor = color ? colorBgMap[color] : "bg-lightgray";

  return getClassName(
    selected ? `${selectedColor} font-bold` : "",
    active ? `${activeColor} !text-black` : "",
    "max-h-none text-body-4 relative mx-2 cursor-default select-none rounded-[10px] py-2 px-2 pl-3 pr-9",
  );
};

export const getOptionListClassName = ({
  color,
}: {
  color?: ComponentColor;
}) => {
  return getClassName(
    color ? `${colorBorderMap[color]} border-[1px]` : "",
    "text-base sm:text-sm absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-[10px] bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
  );
};

export const getColor = ({
  type,
  color,
  invalid,
}: {
  type: "border" | "text";
  color?: ComponentColor;
  invalid?: boolean;
}) => {
  if (invalid) {
    return type === "border" ? "border-[#f43f5d]" : "text-[#f43f5d]";
  } else if (color) {
    return type === "border" ? colorBorderMap[color] : colorMap[color];
  } else {
    return type === "border" ? "border-gray" : "text-gray";
  }
};

export const getListInputClassName = ({
  color,
  invalid,
  whiteBorder,
}: {
  color?: ComponentColor;
  invalid?: boolean;
  whiteBorder?: boolean;
}) => {
  return getClassName(
    "text-body-3 relative h-[50px] w-full rounded-[10px] border-[1px] py-1.5 pr-10 pl-3",
    getColor({ type: "border", color, invalid }),
    color && whiteBorder ? colorBgMap[color] : "bg-transparent",
  );
};

export const getWrapperClassName = ({ disabled }: { disabled?: boolean }) => {
  return getClassName(
    "relative",
    disabled && "opacity-30 [&>button]:pointer-events-none",
    "text-left",
  );
};
